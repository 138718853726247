import { Customer, Product, ProductVariant } from "@medusajs/medusa";

export type FormImage = {
  url: string;
  name?: string;
  size?: number;
  nativeFile?: File;
};

export type Deposit = {
  id: string;
  amount: number;
  currency: string;
  proof: any;
  userId: string;
  created_at: string;
  status: string;
  user: Customer;
};

export type Account = {
  id: number;
  balance: number;
  tag: string;
  userId: string;
  productId: string;
  productVariantId: string;
};

export type Investment = {
  id: number;
  balance: number;
  tag: string;
  userId: string;
  productId: string;
  productVariantId: string;
  product: Product;
  productVariant: ProductVariant;
};
export type Withdrawal = {
  status: string;
  id: string;
  reason: string;
  amount: number;
  userId: string;
  accountId: number;
  account_holder_name: string;
  bank_name: string;
  checking_account: string;
  routing_number: string;
  created_at: Date;
  updated_at: Date;
};
export type StoreDepositAddress = {
  address: string;
  cardImage: string;
  currency: string;
  network: string;
  qrImage: string;
};

// export const BASE_URL = `http://localhost:9000/`;
export const BASE_URL = `https://api.zenithin.com/`;;
